/* eslint-disable no-use-before-define */
// import clevertap from "clevertap-web-sdk";

import { parseCookies } from "nookies";
import { getBrowserName, getOperatingSystem } from "../utils/common";
import { cleverTapCommonEvents } from "./clevertapConstants";
import CookieUtil from "../utils/storage/cookieUtil";
import { fetchGeoLocation } from "../api/common";
import { getPageNameForCleverTap } from "../utils/fnpPageType";

let clevertap;

/**
 *
 * @returns
 */
const useCleverTap = () => {
  /**
   *
   */
  const init = () => {
    try {
      if (typeof window !== "undefined") {
        clevertap = require("clevertap-web-sdk"); // eslint-disable-line global-require
        clevertap.init(process.env.NEXT_PUBLIC_CLEVER_TAP_ACCOUNT_ID, process.env.NEXT_PUBLIC_CLEVER_TAP_REGION);
        customWebSession(
          { page_name: getPageNameForCleverTap(window?.fnpPageType), page_type: window.fnpPageType },
          clevertap?.getCleverTapID(),
        );
        clevertap.privacy.push({ useIP: true });
        // Notification Popup will not get triggered in case of (iOS or MacOS) with Safari Browser.
        if (!((getOperatingSystem() === "iOS" || getOperatingSystem() === "MacOS") && getBrowserName() === "Safari")) {
          clevertap.notifications.push({
            titleText: "Stay Updated!",
            bodyText: "Allow notifications to receive the latest offers and updates.",
            okButtonText: "Allow",
            rejectButtonText: "No, Thanks",
            askAgainTimeInSeconds: 86400,
            okButtonColor: "#737530",
            serviceWorkerPath: "/clevertap_sw.js",
          });
        }
      }
      // clevertap.setDebugLevel(3); // Debug level can be adjusted based on needs
      console.log("CleverTap Initialized");
    } catch (error) {
      console.log("Clevertap not initialized properly");
    }
  };

  /**
   *
   * @param {*} eventName
   * @param {*} properties
   */
  const trackEvent = async (eventName, properties = {}) => {
    try {
      let cleverTapEventData = { ...properties };
      try {
        const cityName = await fetchGeoLocation();
        cleverTapEventData.browsing_city = cityName;
      } catch (geoError) {
        console.log("Failed to fetch geo location");
      }

      customWebSession(cleverTapEventData);
      clevertap.event.push(eventName, cleverTapEventData);
    } catch (error) {
      console.log("Clevertap not initialized properly");
    }
  };

  /**
   * It will get triggered only one time of user inactivity of 20 minutes.
   */
  const customWebSession = (properties, cleverTapID = "") => {
    const { cleverTapWebSession } = parseCookies();
    const operatingSystem = getOperatingSystem();
    const browserName = getBrowserName();
    const cookieTTL = 20 * 60;

    CookieUtil.setCookie("cleverTapWebSession", "webSessionStarted", {
      maxAge: cookieTTL,
      path: "/",
    });

    const params = new URLSearchParams(window.location.search);
    const searchQuery = {};
    params.forEach((value, key) => {
      searchQuery[key] = value;
    });
    const { utm_source: utmSource, utm_medium: utmMedium, utm_campaign: utmCampaign, gclid } = searchQuery;

    if (!cleverTapWebSession) {
      const payload = {
        browser: browserName,
        page_name: properties?.page_name,
        page_type: properties?.page_type,
        complete_url: window.location.href,
        clean_url: `${window.location.origin}${window.location.pathname}`,
        operating_system: operatingSystem,
        utm_source_name: utmSource,
        utm_medium_name: utmMedium,
        utm_campaign_name: utmCampaign,
        login_status: properties?.login_status,
        ctid: cleverTapID || properties?.ctid,
        gclid,
      };

      trackEvent(cleverTapCommonEvents.sessionStart, payload);
    }
  };

  /**
   *
   * @param {*} properties
   */
  const onUserLogin = (properties) => {
    try {
      clevertap.onUserLogin.push(properties);
    } catch (error) {
      console.log("Clevertap not initialized properly");
    }
  };

  return { init, trackEvent, onUserLogin };
};

export default useCleverTap;
