import isEmpty from "lodash.isempty";
import { useEffect } from "react";

/**
 * Omniture setup hook
 *
 * @param {object} state redux state data for omniture
 * @param {object} dependency .
 */
export default function useOmnitureSetup(state, dependency, condition = true) {
  useEffect(() => {
    /**
     * set omniture data
     */
    const loadOmniture = async () => {
      if (typeof window !== "undefined") {
        const { default: initOmniSetup } = await import("../../analytics/omniture/omniture");
        if (typeof initOmniSetup === "function") {
          initOmniSetup(state);
        }
      }
    };
    if (!isEmpty(state?.userDetails?.checkLoginInfo) && condition) {
      loadOmniture();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependency);
}
