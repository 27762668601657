export const VERIFY_DETAILS = "Verify Details";
export const COUNTRY_NOT_FOUND = "Country not found";
export const DO_YOU_WANT_CHANGE_EMAIL = "Do you want to change the email ?";
export const LOGIN_TYPOGRAPHY = {
  mainHeading: "Sign Up/Login to FNP!",
  subHeading: "For a personalized experience & faster checkout",
};
export const SIGNUP_TYPOGRAPHY = {
  mainHeading: "Welcome To FNP!",
  subHeading: "Happiness is just a click away",
};
export const EMAIL_INPUT = { placeholder: "Enter email address", errorMessage: "Please enter valid email ID" };
export const NAME_INPUT = { placeholder: "Name", errorMessage: "Please enter your name" };
export const PHONE_INPUT = {
  placeholder: "Enter Mobile Number",
  errorMessage: "Please enter valid 10 digit mobile no.",
};
export const CONTINUE = "Continue";
export const START_GIFTING = "Start Gifting";
export const FOOTER_TYPOGRAPHY = {
  mainHeading: "By continuing you agree to FNP’s",
  tnc: "Terms & Conditions",
  privacyPolicy: "Privacy Policy",
  ampersand: "&",
  disclaimer: "Disclaimer",
};
export const OTP_TYPOGRAPHY = {
  validFor: "Valid for 10 mins",
  resendOtp: "Resend OTP",
  confirmOTP: "Confirm OTP",
  otpSendMsg: "OTP sent to your mobile & email ID",
};

export const OR_LOGIN_WITH = "or Login with";
export const ACCOUNT_CREATED_SUCCESSFULLY = "🎉 Account created successfully";
export const LOGGED_IN_SUCCESSFULLY = "🎉 Logged in successfully";
